@media (min-width: 768px) and (max-width: 1200px) {
    header{
        .botoes{
            ul{
                li{
                    a{
                        .d-desk{
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
    footer{
        .produtos-rodape{
            .coluna{
                float: none !important;
            }
        }
    }
}