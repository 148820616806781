.d-mobile {
	display: none !important;
}
.d-desk {
	display: block !important;
}

html,
body{
    min-height: 100%;
    height: 100%;
	color: $preto;
	font-family: 'Lato', sans-serif;
}
img{
    max-width: 100%;
    height: auto;
}
a,
a:hover,
a:visited,
a:active{
    color: inherit;
    text-decoration: inherit;
}
a{
	-webkit-transition: all .3s ease;
    transition: all .3s ease;
}

button{
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	cursor: pointer;
}

// MIXINS ///////////////////////////
@mixin transition($value) {
    -webkit-transition: all $value ease;
    transition: all $value ease;
}

@mixin backgroudsize($value) {
    -webkit-background-size: $value;
    -moz-background-size: $value;
    -o-background-size: $value;
    background-size: $value;
}
@mixin gradient($angle, $gradient1, $gradient2){
    background-image: -webkit-gradient(linear, $angle, from($gradient1), to($gradient2));
    background-image: -webkit-linear-gradient($angle, $gradient1, $gradient2);
    background-image:    -moz-linear-gradient($angle, $gradient1, $gradient2);
    background-image:     -ms-linear-gradient($angle, $gradient1, $gradient2);
    background-image:      -o-linear-gradient($angle, $gradient1, $gradient2);
    background-image:         linear-gradient($angle, $gradient1, $gradient2);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=$angle,StartColorStr='#{$gradient1}', EndColorStr='#{$gradient2}');
}
///////////////////////////////////

.center-this{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}
.center-column{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}
.center-between{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: center;
}
.align-center-left{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;
	align-content: center;
}
.align-center-right{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	align-content: stretch;
}
.align-start-left{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
}
.align-end-bottom-right {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: flex-end;
}
.center-space-around{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}
.align-bottom-center{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: flex-end;
	align-content: center;
}