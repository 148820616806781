.banner-cat{
    @include backgroudsize(cover);
    background-position: center;
    height: 350px;
    h1{
        color: $branco;
        margin-top: 100px;
        font-size: 60px;
    }
}
.banner-cat-tipo{
    @include backgroudsize(cover);
    background-position: center;
    height: 350px;
    margin-bottom: 100px;
    .fundo-tipo{
        .img{
            text-align: center;
            img{
                margin-top: 80px;
                max-height: 380px;
                //max-width: 70%;
            }
        }
        .tipo{
            text-align: right;
            h1{
                text-transform: uppercase;
                color: $branco;
                font-size: 70px;
                font-weight: bold;
                margin-top: 130px;
            }
        }
        
    }
}
.produtos-cat{
    ul{
        padding: 0;
        text-align: center;
        padding: 40px 175px;
        li{
            display: inline-block;
            a{
                padding: 8px;
                border: 2px solid $preto;
                border-radius: 40px;
                display: inline-block;
                width: 200px;
                margin: 8px;
                .icon{
                    width: 100%;
                    height: 85px;
                    padding: 5px 53px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                h5{
                    font-size: 28px;
                    margin-bottom: 0;
                    font-weight: bold;
                }
                p{
                    margin-bottom: 0;
                }
                button{
                    background: transparent;
                    border: 0;
                    color: $laranja;
                    font-weight: bold;
                }
                &:hover{
                    background-color: $laranja;
                    color: $branco;
                    border-color: $laranja;
                    .icon{
                        img{
                            //-webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
                            //filter: grayscale(100%) !important;
                            -webkit-filter: brightness(500%); /* Safari */
                            filter: brightness(500%);
                        }
                    }
                    button{
                        color: $branco;
                    }
                }
            }
        }
        .linha-ex{
            a{
                background-color: $laranja;
                color: $branco;
                border: 0;
                &:hover{
                    background-color: $preto;
                }
            }
            button{
                color: $branco;
            }
        }
        .catalogo{
            a{
                background-color: $verde;
                color: $branco;
                border: 0;
                &:hover{
                    background-color: $preto;
                }
            }
            button{
                color: $branco;
            }
        }
    }
}
.diferenciais{
    ul{
        padding: 0;
        text-align: center;
        //padding: 40px 175px;
        li{
            vertical-align: top;
            display: inline-block;
            width: 200px;
            margin: 15px;
            .item{
                padding: 20px 10px;
                border: 2px solid $verde;
                border-radius: 50px;
                display: inline-block;
                .icon{
                    width: 100%;
                    height: 85px;
                    padding: 5px 53px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                h5{
                    text-transform: uppercase;
                    font-size: 20px;
                    margin-bottom: 0;
                    font-weight: bold;
                    color: $verde;
                }
            }
            p{
                //@extend: 0 8px;
                font-size: 11px;
                margin-top: 8px;
                margin-bottom: 0;
            }
        }
    }
}

.produtos-carrossel{
    margin-top: 30px;
    .item{
        display: flex;
        span{
            color: $laranja;
        }
        h2{
            text-transform: uppercase;
            font-weight: bold;
            font-size: 25px;
            margin: 0;
        }
        p{
            color: $laranja;
            margin: 0;
        }
        ul{
            margin-top: 10px;
            margin-bottom: 0;
        }
        .descricao{
            min-height: 50px;
            margin-top: -8px;
            margin-bottom: 10px;
            p{
                margin-top: 10px;
                color: $preto;
                padding-left: 40px;
            }
        }
        button{
            //display: inline-block;
            padding: 5px 12px;
            font-size: 16px;
            color: $branco;
            background: $laranja;
            border: 0;
            border-radius: 20px;
            text-align: left;
            margin-bottom: 10px;
            font-weight: bold;
            &:hover{
                background: $preto;
                cursor: pointer;
            }
        }
        .cor{
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-left: 5px;
            border: 1px solid $cinza_medio;
        }
        .orcamento{
            padding: 5px 10px;
            background-color: $laranja;
            border-radius: 15px;
            margin-bottom: 5px;
            color: $branco;
        }
        .completo{
            padding: 5px 10px;
            background-color: $verde;
            border-radius: 15px;
            color: $branco;
        }
    }
    .owl-nav{
        width: 97%;
        .owl-prev{
            position: absolute;
            top: 30%;
            i{
                font-size: 50px;
                padding-left: 15px;
                color: $laranja;
            }
        }
        .owl-next{
            position: absolute;
            top: 30%;
            right: 0;
            i{
                font-size: 50px;
                padding-right: 15px;
                color: $laranja;
            }
        }
    }
}

.under-slider{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    min-height: 110px;
    .slider-small-item{
        cursor: pointer;
        img{
            max-width: 100px;
            opacity: 0.5;
        }
        &:hover{
            img{
                opacity: 1;
            }
        }
    }
    .active{
        border-bottom: 4px solid $laranja;
        img{
            opacity: 1;
        }
    }
}

.detalhes-produtos{
    background-color: $laranja;
    padding-top: 40px;
    padding-bottom: 40px;
    color: $branco;
    h3{
        text-transform: uppercase;
        font-size: 50px;
        margin-bottom: 30px;
    }
    ul{
        padding: 0;
        li{
            display: inline-block;
            h4{
                text-transform: uppercase;
            }
        }
    }
}