.contato-page{
    padding-top: 70px;
    .formulario{
        .texto-form{
            h2{
                font-family: "Open Sans", sans-serif;
                font-weight: 600;
                font-size: 22px;
                color: $preto;
                padding-bottom: 10px;
            }
            p{
                font-family: "Open Sans", sans-serif;
                font-size: 18px;
                color: $preto;
            }
            ul{
                padding: 0;
                display: inline-grid;
                li{
                    display: list-item;
                    list-style-type: none;
                    i{
                        color: $verde_escuro;
                        float: left;
                        width: 30px;
                        height: 35px;
                        margin-right: 15px;
                        font-size: 30px;
                    }
                    .item{
                        color: $verde_escuro;
                        float: left;
                        font-size: 10px;
                        font-weight: 600;
                        span{
                            display: block;
                            margin-bottom: -20px;
                        }
                        a{
                            font-weight: normal;
                            font-size: 15px;
                            color: $verde_escuro;                 
                        }
                    }
                }
            }
        }
        .form{
            .campos{
                width: 100%;
                padding-bottom: 20px;
                p{
                    font-weight: 600;
                    font-size: 12px;
                    color: $preto;
                    margin-bottom: 3px;
                }
                input{
                    width: 100%;
                    height: 45px;
                    outline: none;
                    padding-left: 7px;
                    border: 2px solid $verde !important;
                    border-radius: 25px !important;
                    color: $verde !important;
                    font-weight: bold;
                    padding-left: 15px;
                    &::placeholder {
                        color: $verde !important;
                    }
                }
                textarea{
                    width: 100%;
                    height: 200px;
                    border: 1px solid $cinza_claro;
                    outline: none;
                    resize: none;
                    padding-left: 7px;
                    border: 2px solid $verde !important;
                    border-radius: 25px !important;
                    color: $verde !important;
                    font-weight: bold;
                    padding-left: 15px;
                    &::placeholder {
                        color: $verde !important;
                    }
                }
            }
            .enviar{
                width: 100%;
                button{
                    background-color: $verde_escuro;
                    border: 0;
                    width: 100%;
                    height: 60px;
                    color: $branco;
                    font-weight: 600;
                    font-size: 15px;
                    outline: none;
                    transition: 0.3s;
                    border-radius: 30px !important;
                    &:hover{
                        background-color: $laranja;
                        color: $branco;
                    }
                }
            }
        }
    }
    .localizacao{
        padding: 60px 0;
        .texto-local{
            h2{
                font-family: "Open Sans", sans-serif;
                font-weight: 600;
                font-size: 22px;
                color: $preto;
                padding-bottom: 10px;
            }
            .icon{
                color: $verde_escuro;
                float: left;
                width: 30px;
                height: 35px;
                margin-right: 20px;
                font-size: 30px;
            }
            .end{
                width: 200px;
                color: $verde_escuro;
                float: left;
                font-size: 14px;
            }
        }
        .google-maps{
            p{
                font-size: 18px;
                color: $preto;
            }     
            .mapa{
                iframe{
                    width: 100% !important;
                    height: 500px !important;
                }
            }   
        }
    }
}