header{
    margin-top: 10px;
    z-index: 9999;
    position: relative;
    .busca{
        margin-top: 50px;
        padding-left: 40px;
        input{
            padding: 3px 40px 3px 8px;
            width: 98%;
            border-radius: 25px;
            border: 2px solid #333;
            //@include transition(.5s);
            &:focus {
                outline: 0;
                outline-offset: 0;
                border: 2px solid $laranja;
            }
        }
        button{
            cursor: pointer;
            margin-left: -38px;
            border: 0;
            background-color: transparent;
        }
    }
    .botoes{
        text-align: right;
        margin-top: 45px;
        ul{
            padding: 0;
            li{
                display: inline-block;
                margin-right: 20px;
                a{
                    display: flex;
                    align-items: center;
                    img{
                        margin-right: 5px;
                        margin-bottom: 6px;
                        height: 45px;
                        width: auto;
                    }
                    span{
                        font-size: 16px;
                    }
                    &:hover{
                        color: $laranja;
                    }
                }
            }
            .link-carrinho{
                display: flex;
                color: $laranja;
                .total-carrinho-header{
                    position: absolute;
                    background-color: #000;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    text-align: center;
                    margin-left: 9px;
                    margin-top: -20px;
                    display: block;
                }
                img{
                    margin-right: -5px;
                    @include transition(.3s);
                }
                &:hover{
                    color: $preto;
                    font-weight: bold;
                    .total-carrinho-header{
                        background-color: $laranja;
                    }
                    img{
                        -webkit-filter: grayscale(100%) !important;
                        filter: grayscale(100%) !important;
                        margin-right: -7.2px;
                    }
                }
            }
            .toggle{
                display: none;
            }
        }
        .redes{
            margin-top: 10px;
            text-align: right;
            li{
                margin-left: 1.5%;
                margin-right: 0;
                a{
                    display: block;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-color: $preto;
                    text-align: center;
                    padding-top: 5px;
                    i{
                        color: $branco;
                    }
                    &:hover{
                        background-color: $laranja;
                    }
                }
                .facebook{
                    background-color: $face;
                }
                .instagram{
                    background-color: $insta;
                }
                .linkedin{
                    background-color: $linke;
                }
            }
            
        }
    }
    .menu-principal{
        margin-top: 20px;
        background-color: $preto;
        .itens-menu{
            ul{
                text-align: center;
                padding: 0;
                margin-bottom: 0;
                li{
                    display: inline-block;
                    @include transition(.3s);
                    a{
                        display: block;
                        padding: 20px 30px;
                        color: $branco;
                        font-size: 17px;
                    }
                    &:hover{
                        background-color: $laranja_escuro;
                    }
                    ul{
                        position: absolute;
                        background-color: $laranja;
                        text-align: left;
                        padding: 10px 30px;
                        min-width: 222px;
                        border-radius: 0 0 35px 35px;
                        overflow: hidden;
                        // SOLUÇÃO p/ transição height 0 p/ auto ////////
                        transform: scaleY(0);
                        transform-origin: top;
                        opacity: 0;
                        transition: transform .3s ease;
                        ////////////////////////////////////////////////
                        li{
                            display: list-item !important;
                            list-style: none;
                            border-radius: 15px;
                            padding: 0 5px;
                            a{
                                display: block;
                                padding: 5px;
                                font-size: 16px;
                                color: $branco;
                                width: 100%;
                                text-align: left;
                                border-radius: 30px;
                                &:hover{
                                    color: $preto;
                                    background-color: $laranja_escuro;
                                }
                            }
                        }
                    }
                    &:hover{
                        ul{
                            transform: scaleY(1);
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
.avisos{
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 9999;
    animation-timing-function: ease;
    animation-name: avisos;
    animation-duration: 1s;
    animation-delay: 7s;
    animation-fill-mode: forwards;
    opacity: 1;
    @keyframes avisos {
        from {opacity: 1;}
        to {opacity: 0;}
    }
    .alert{
        color: $branco;
        margin-bottom: 0;
    }
}
.avisos-danger{
    background-color: $preto;
}
.avisos-success{
    background-color: $laranja;
}
.grupo-alert-carrinho{
    visibility: hidden;
    //display: none;
    //margin-right: 15px;
    //margin-top: 15px;
    //right: -500px;
    top: 0;
    position: fixed;
    background-color: $laranja;
    width: 100%;
    z-index: 9999;
    opacity: 0;
    a{
        color: $laranja;
        background-color: $branco;
    }
    @include transition(.6s);
    .alert-carrinho{
        color: $branco;
        font-weight: bold;
    }
    a{
        text-align: left;
        span{
            display: inline-block;
        }
    } 
}
.mostrar-aviso-carrinho{
    visibility: visible;
    opacity: 1;
    //right: 15px;
}