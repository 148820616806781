@import "common/variables";

@import url('https://fonts.googleapis.com/css?family=Lato:400,900&display=swap');
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../../bower_components/bootstrap/scss/bootstrap.scss";
@import "../../../bower_components/bourbon/app/assets/stylesheets/_bourbon.scss";
// endbower

@import "common/global";

@import "layouts/footer";
@import "layouts/header";
@import "layouts/home";
@import "layouts/produtos";
//@import "layouts/orcamento";
@import "layouts/contato";
@import "layouts/carrinho";
@import "layouts/busca";
@import "layouts/faq";

@import "responsive/tablet-landscape";
@import "responsive/tablet-portrait";
@import "responsive/mobile";