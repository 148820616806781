.slide-home-header{
    .owl-nav{
        width: 97%;
        .owl-prev{
            opacity: 0.5;
            left: 3%;
            position: absolute;
            top: 45%;
            border: 2px solid $laranja;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            i{
                font-size: 50px;
                color: $laranja;
                margin-top: -6px;
                margin-left: -4px;
            }
            &:hover{
                opacity: 1;
            }
        }
        .owl-next{
            opacity: 0.5;
            right: 3%;
            position: absolute;
            top: 45%;
            border: 2px solid $laranja;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            i{
                font-size: 50px;
                color: $laranja;
                margin-top: -6px;
                margin-right: -4px;
            }
            &:hover{
                opacity: 1;
            }
        }
    }
}
.sobre-nos{
    @include backgroudsize(cover);
    background-position: center;
    padding: 30px 0;
    h2{
        margin-top: 30px;
        font-size: 65px;
        text-transform: uppercase;
    }
    p{
        font-size: 16px;
        //text-transform: uppercase;
    }
}
.faixa-produtos{
    background-color: $laranja;
    padding: 30px 0;
    color: $branco;
    h3{
        font-size: 50px;
        margin-bottom: 30px;
    }
    .icones{
        padding: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        li{
            position: relative;
            padding: 25px;
            width: 18%;
            height: 118px;
            margin: 25px;
            display: flex;
            justify-content: center;
            img{
                max-width: 64%;
                max-height: 100%;
                position: absolute;
                bottom: 0;
            }
        }
    }
    .texto{
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 50px;
        li{
            width: 18%;
            display: inline-block;
            text-align: center;
            margin: -25px 25px 0;
            p{
                font-size: 22px;
                line-height: 1.5rem;
                margin-top: 8px;
            }
        }
    }
    .bt-confira-pdt{
        text-align: center;
        padding-bottom: 30px;
        a{
            padding: 10px 30px;
            background-color: $branco;
            color: $laranja;
            border-radius: 20px;
            font-weight: 900;
            &:hover{
                background-color: $preto;
            }
        }
    }
}
.depoimentos{
    padding: 40px 0;
    h3{
        font-size: 60px;
        margin-bottom: 40px;
    }
    .slide-depo{
        .item{
            display: flex;
            .img-depo{
                width: 150px;
                height: 150px;
                border-radius: 50%;
                @include backgroudsize(cover);
                background-position: center;
            }
            h4{
                color: $laranja;
                font-size: 25px;
                text-transform: uppercase;
            }
            p{
                text-transform: uppercase;
                font-size: 15px;
            }
            .stars{
                ul{
                    padding: 0;
                    li{
                        display: inline-block;
                        width: 22px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .owl-nav{
            position: absolute;
            top: 25%;
            width: 100%;
            .owl-prev{
                i{
                    font-size: 50px;
                    padding-left: 15px;
                }
            }
            .owl-next{
                float: right;
                i{
                    font-size: 50px;
                    padding-right: 15px;
                }
            }
        }
        .owl-dots {
            width: 100%;
            //position: absolute;
            z-index: 999;
            text-align: center;
            display: flex;
            justify-content: center;
            .owl-dot{
                span{
                    margin: 3px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 1px solid $laranja;
                }
            }
            .active{
                span{
                    background-color: $laranja;
                }
            }
        }
    }
}
.clientes{
    padding: 40px 0;
    h3{
        font-size: 60px;
        margin-bottom: 40px;
        color: $laranja;
    }
    .slide-clientes{
        .item{
            padding: 0 10px;
            height: 110px;
            display: flex;
            align-items: center;
            img {
                vertical-align: middle;
            }
        }
        .owl-dots {
            width: 100%;
            //position: absolute;
            z-index: 999;
            text-align: center;
            display: flex;
            justify-content: center;
            .owl-dot{
                span{
                    margin: 3px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 1px solid $laranja;
                }
            }
            .active{
                span{
                    background-color: $laranja;
                }
            }
        }
    }
}