$branco: #fff;
$preto: #000;
$cinza: #888888;
$cinza_claro: #dfdfdf;
$cinza_medio: #b7b7b7;
$cinza_escuro: #555555;
$vermelho: #cd0000;
$laranja_escuro: #e66110;
$laranja: #ef801e;
$verde: #267046;
$verde_escuro: #175432;

//CORES REDES
$face: #3b5998;
$insta: #cb38a6;
$linke: #007bb6;