.banner-orcamento{
    background-color: $preto;
    height: 300px;
    h1{
        color: $branco;
        margin-top: 90px;
        font-size: 60px;
        font-weight: bold;
    }
}
.carrinho{
    h2{
        font-weight: bold;
        font-size: 50px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .itens-carrinho{
        padding: 0;
        li{
            list-style: none;
            &:hover{
                .box-pdt{
                    border: 1px solid $laranja;
                    box-shadow:inset 0 0 0 5px $laranja;
                }
            }
            .box-pdt{
                min-height: 180px;
                margin-top: 20px;
                padding: 15px;
                border: 1px solid $verde;
                box-shadow:inset 0;
                border-radius: 60px;
                @include transition(.3s);
                h4{
                    text-transform: uppercase;
                    font-size: 25px;
                    color: $verde;
                    font-weight: bold;
                }
                ul{
                    margin-top: 10px;
                    margin-bottom: 0;
                    li{
                        list-style: disc;
                    }
                }
                .descricao{
                    min-height: 50px;
                    margin-top: -8px;
                    margin-bottom: 10px;
                    p{
                        margin-top: 10px;
                        color: $preto;
                        padding-left: 40px;
                    }
                }
                .apagar-item-carrinho{
                    opacity: 0.3;
                    &:hover{
                        opacity: 1;
                    }
                }
            }
            .opcoes-pdt{
                padding: 0 40px;
                margin-top: -20px;
                .title-qtd{
                    background-color: $laranja;
                    color: #fff;
                    text-align: center;
                    padding: 7px;
                    border-radius: 25px;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }
                .btn-decrement, .btn-increment{
                    vertical-align: top;
                    cursor: pointer;
                    font-weight: bold;
                    width: 35px;
                    margin-right: -5px;
                    margin-left: -5px;
                    background-color: $laranja;
                    border: 0;
                    color: $branco;
                    height: 40px;
                    //border: 1px solid #999 !important;
                }
                .btn-decrement{
                    border-radius: 20px 0 0 20px;
                    margin-left: 4px;
                }
                .btn-increment{
                    border-radius: 0 20px 20px 0;
                }
                input[type="button"]:disabled{
                    cursor: not-allowed;
                }
                .input-qtd{
                    vertical-align: top;
                    cursor: default;
                    text-align: center;
                    width: 43px;
                    -moz-appearance:textfield;
                    height: 40px;
                    background-color: darkslategray;
                    border: 0;
                    font-weight: bold;
                    color: $branco;
                }
                .input-qtd::-webkit-outer-spin-button,
                .input-qtd::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                .btn-up{
                    cursor: pointer;
                    padding: 0 15px;
                    background-color: $verde;
                    border: 0;
                    border-radius: 30px;
                    height: 40px;
                    color: $branco;
                    margin-left: 4px;
                    &:hover{
                        background-color: $laranja;
                    }
                    &:disabled{
                        cursor: default;
                        opacity: 0.5;
                        &:hover{
                            background-color: $verde;
                        }
                    }
                }
                .aviso-decrement{
                    font-weight: bold;
                }
                .escolha-cor{
                    p{
                        text-transform: uppercase;
                        background-color: $verde;
                        color: $branco;
                        text-align: center;
                        padding: 7px;
                        border-radius: 25px;
                        margin-bottom: 5px;
                    }
                    .radio-cores{
                        text-align: center;
                        padding: 0;
                        li{
                            display: inline-block;
                            //border-right: 2px solid $cinza_claro;
                            padding-top: 12px;
                            padding-left: 5px;
                            input[type="checkbox"] {
                                //display: none;
                                position: relative;
                                margin-bottom: -20px;
                                z-index: -1;
                                opacity: 0;
                                &:checked + label span img {
                                    opacity: 1 !important;
                                }
                            }
                            label{
                                cursor: pointer;
                                span{
                                    display: inline-block;
                                    border: 2px solid $cinza_medio;
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    margin-right: -2px;
                                    img{
                                        opacity: 0;
                                        margin-top: 4px;
                                        @include transition(.3s);
                                    }
                                }
                            }
                        }
                        .item-qtd{
                            display: inline-block;
                        }
                        .corescolhida{
                            background-color: #f4f4f4;
                            border-bottom: 2px solid $laranja;
                        }
                    }
                }
            }
        }
    }

    .btn-persona{
        margin-top: 20px;
        display: block;
        text-align: center;
        color: $branco !important;
        font-size: 19px;
        border-radius: 40px;
        line-height: 37px;
        padding: 5px 15px;
        &:hover{
            color: $branco !important;
            opacity: 1;
        }
    }
    .continuar-comprando{
        background-color: $preto;
        &:hover{
            background-color: $laranja;
        }
    }
    
    .btn-ativo{
        opacity: 1;
    }
    .btn-inativo{
        opacity: 0.3;
    }

    .botoes-persona{
        input[type="radio"] {
            display: none;
            &:checked + label img {
                opacity: 1;
            }
        }
        label{
            cursor: pointer;
            display: flex;
            .box{
                margin-right: -42px;
                margin-top: 25px;
                border-radius: 50%;
                width: 36px;
                height: 36px;
                padding: 10px;
                z-index: 1;
                background-color: $branco;
                img{
                    position: absolute;
                    width: 40px;
                    margin-top: -6px;
                    opacity: 0;
                    margin-left: -9px;
                }
            }
            span{
                width: 100%;
                margin-top: 20px;
                display: block;
                text-align: center;
                color: $branco !important;
                font-size: 17px;
                border-radius: 40px;
                line-height: 37px;
                padding: 5px 15px 5px 50px;
            }
            .persona{
                background-color: $verde;
            }
            .naopersona{
                background-color: $laranja;
            }
            &:hover{
                span{
                    background-color: $preto;
                }
            }
        }
    }

    .produto-personalizado{
        overflow: hidden;
        margin-top: 45px;
        height: 0;
        h3{
            font-size: 40px;
            font-weight: bold;
        }
        @include transition(.3s);
        input[type="file"]{
            background-color: $verde;
            padding: 10px 20px;
            border-radius: 25px;
            color: $branco;
            width: 100%;
        }
        input[type="file"]::-webkit-file-upload-button {
            color: $branco;
            border: 0;
            background: transparent;
            text-transform: uppercase;
            font-weight: bold;
        }
        #file-name{
            position: absolute;
            right: 0;
            margin-top: -38px;
            margin-right: 30px;
            display: block;
            color: $branco;
        }
        .escolha-num-cor{
            margin-top: 60px;
            .titulo{
                font-weight: bold;
                color: $verde;
            }
            ul{
                padding: 0;
                li{
                    display: inline-block;
                    input[type="radio"] {
                        display: none;
                        &:checked + label img {
                            opacity: 1;
                        }
                    }
                    label{
                        cursor: pointer;
                        display: flex;
                        width: 150px;
                        .box{
                            border: 2px solid $verde;
                            border-radius: 30px;
                            width: 60px;
                            height: 50px;
                            padding: 10px;
                            img{
                                position: absolute;
                                width: 45px;
                                margin-top: -6px;
                                opacity: 0;
                            }
                        }
                        span{
                            display: block;
                            font-size: 18px;
                            color: $verde;
                            font-weight: bold;
                            margin-left: 5px;
                            margin-top: 10px;
                        }
                        button{
                            margin-left: 5px;
                            margin-top: 10px;
                            background-color: $preto;
                            width: 25px;
                            height: 25px;
                            color: $branco;
                            border: 0;
                            border-radius: 50%;
                            font-weight: bold;
                        }
                    }
                }
            }
    
        }
    }
    .personalizado-ativo{
        height: 260px;
    }
    .btn-enviar{
        text-align: right;
        margin: 40px 0;
        button{
            cursor: pointer;
            border: 0;
            border-radius: 25px;
            padding: 10px 30px;
            color: $branco;
            background-color: $verde;
            font-size: 25px;
            line-height: 30px;
            display: none;
            &:hover{
                color: $preto;
                background-color: $laranja;
            }
        }
        button:disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }
    .formulario-carrinho{
        overflow: hidden;
        height: 0;
        @include transition(.3s);
        h3{
            //margin-top: 20px;
            font-size: 40px;
            font-weight: bold;
        }
        input{
            border: 2px solid $verde !important;
            border-radius: 25px !important;
            color: $verde !important;
            font-weight: bold;
            &::placeholder {
                color: $verde !important;
            }
        }
        select{
            border: 2px solid $verde !important;
            border-radius: 25px !important;
            color: $verde !important;
            font-weight: bold;
        }
        textarea{
            border: 2px solid $verde !important;
            border-radius: 25px !important;
            color: $verde !important;
            font-weight: bold;
            &::placeholder {
                color: $verde !important;
            }
        }
    }
    .formulario-carrinho-ativo{
        height: 460px;
    }
    .btn-submit-ativo{
        display: inline-block !important;
    }
    .btn-carrinho-vazio{
        padding: 10px 20px;
        color: $branco;
        background-color: $laranja;
        border-radius: 30px;
        margin-left: 20px;
        font-size: 20px;
        font-weight: bold;
        &:hover{
            background-color: $laranja_escuro;
        }
    }
}
.obrigado{
    background-color: $preto;
    height: 350px;
    h1{
        color: $branco;
        margin-top: 70px;
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 30px;
    }
    h2{
        color: $laranja;
        font-weight: bold;
    }
    p{
        color: $verde;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 30px;
        span{
            color: $branco;
        }
    }
}