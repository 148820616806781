footer{
    .cadastre-se{
        padding: 30px 0;
        background-color: $verde;
        h4{
            color: $branco;
            text-transform: uppercase;
        }
        input{
            border-radius: 25px;
            padding: 5px;
            border: 0;
            width: 75%;
            float: left;
        }
        button{
            border-radius: 25px;
            background-color: $verde_escuro;
            border: 0;
            padding: 5px 30px;
            color: $branco;
            margin-left: 1%;
            width: 23%;
            float: left;
        }
    }
    .rodape{
        padding: 40px 0;
        background-color: $preto;
        color: $branco;
        font-size: 10px;
        hr{
            border-top: 1px solid $cinza_escuro;
        }
        .logo-rodape{
            flex: 0 0 15%;
            max-width: 15%;
            img{
                width: 120px;
            }
        }
        .links-rodape{
            margin-top: 20px;
            flex: 0 0 36%;
            max-width: 36%;
            a{
                display: block;
                margin-bottom: 7px;
            }
            .coluna{
                float: left;
                padding-left: 5px;
                padding-right: 10px;
            }
            .produtos-rodape{
                padding-left: 10px;
                height: 100%;
                border-left: 1px solid $cinza_escuro;
                a{
                    font-weight: bold;
                }
            }
        }
        .txt{
            margin-top: 20px;
            flex: 0 0 28%;
            max-width: 28%;
        }
        .redes-rodape{
            margin-top: 20px;
            .zap{
                text-align: right;
                span{
                    color: $verde;
                }
                img{
                    width: 25px;
                    margin-left: 5px;
                }
            }
            ul{
                padding: 0;
                margin-top: 10px;
                text-align: right;
                li{
                    margin-left: 5px;
                    margin-right: 0;
                    display: inline-block;
                    a{
                        padding-top: 3px;
                        display: block;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: $branco;
                        text-align: center;
                        i{
                            font-size: 15px;
                            color: $preto;
                        }
                    }
                }
            }
        }
    }
    .zaplink{
        position: fixed;
        text-align: left;
        margin-bottom: 20px;
        z-index: 9999;
        bottom: 0;
        left: 20px;
        img{
            max-width: 60px;
        }
    }
}