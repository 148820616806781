@media only screen and (max-width: 768px) {
    .d-mobile {
        display: block !important;
    }
    .d-desk {
        display: none !important;
    }
    header{
        .botoes{
            ul{
                padding: 0;
                li{
                    margin-right: 10px;
                    vertical-align: bottom;
                    a{
                        img{
                            height: 30px;
                        }
                        span{
                            font-size: 12px;
                        }
                    }
                    .link-carrinho{
                        img{
                            height: 35px;
                        }
                        .total-carrinho-header{
                            width: 20px;
                            height: 20px;
                            margin-top: -22px;
                            margin-left: 4px;
                        }
                    }
                }
                .toggle{
                    display: inline-block;
                    margin-left: 10px;
                    margin-right: 0;
                    i{
                        font-size: 28px;
                    }
                }
            }
        }
        .menu-principal{
            margin-top: 0;
            overflow: hidden;
            height: 0;
            min-height: 0;
            @include transition(.3s);
            .itens-menu{
                ul{
                    display: block !important;
                    li{
                        display: list-item;
                        ul{
                            display: none !important;
                        }
                    }
                }
            }
        }
        .menu-ativo{
            height: 330px;
        }
    }

    .grupo-alert-carrinho{
        .close{
            margin-right: -10px;
        }
        span{
            display: none !important;
        }
    }

    .sobre-nos h2 {
        font-size: 35px;
    }

    .faixa-produtos{
        h3{
            font-size: 38px;
        }
    }

    .faixa-produtos{
        h3{
            text-align: center;
            font-size: 40px;
        }
        .icones{
            flex-wrap: wrap;
            text-align: center;
            align-items: center;
            li{
                display: inline-block;
                width: 35%;
                height: 90px;
                margin: 20px 20px 60px 20px;
                text-align: center;
                padding: 0;
                img{
                    position: relative;
                    bottom: 0;
                    margin-bottom: 10px;
                }
            }
        }
        .bt-confira-pdt{
            padding-top: 40px;
            padding-bottom: 30px;
        }
    }

    .depoimentos{
        padding: 40px 15px;
        h3{
            text-align: center;
            font-size: 35px;
        }
        .slide-depo{
            .item{
                display: block;
                text-align: center;
                padding: 0 15px;
                .content-img{
                    display: flex;
                    justify-content: center;
	                align-items: center;
                }
                p{
                    font-size: 13px;
                }
            }
            .owl-nav{
                .owl-prev{
                    i{
                        padding-left: 0;
                    }
                }
                .owl-next{
                    i{
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .clientes{
        padding: 40px 15px;
        h3{
            text-align: center;
            font-size: 25px;
        }
        .slide-clientes{
            .item{
                height: 165px;
                img {
                    vertical-align: middle;
                }
            }
        }
    }

    .banner-cat {
        height: 250px;
        margin-bottom: 30px;
        h1 {
            margin-top: 80px;
            font-size: 40px;
        }
    }
    .produtos-cat{
        ul{
            padding: 0;
        }
    }

    .banner-cat-tipo{
        height: 245px;
        margin-bottom: 75px;
        .fundo-tipo {
            .tipo h1 {
                font-size: 40px;
                margin-top: 40px;
                text-align: center;
            }
            .img img {
                margin-top: 25px;
                max-height: 180px;
            }
        }
    }

    .diferenciais{
        ul{
            li{
                width: 100%;
                margin: 0 0 20px 0;
                .item{
                    display: flex !important;
                    padding: 0px 10px;
                    .icon{
                        width: 35%;
                        height: 85px;
                        padding: 10px;
                    }
                    h5{
                        text-align: left;
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .produtos-carrossel-class{
        margin-top: 30px;
        .item{
            display: flex;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #d0d0d0;
            text-align: center;
            img{
                margin-bottom: 15px;
            }
            span{
                color: $laranja;
            }
            h2{
                text-transform: uppercase;
                font-weight: bold;
                font-size: 25px;
                margin: 0;
            }
            p{
                color: $laranja;
                margin: 0;
            }
            ul{
                margin-top: 10px;
                margin-bottom: 0;
                text-align: left;
            }
            .descricao{
                min-height: 50px;
                margin-top: -8px;
                margin-bottom: 10px;
                p{
                    margin-top: 10px;
                    color: $preto;
                    padding-left: 40px;
                }
            }
            button{
                //display: inline-block;
                padding: 5px 12px;
                font-size: 16px;
                color: $branco;
                background: $laranja;
                border: 0;
                border-radius: 20px;
                text-align: left;
                margin-bottom: 10px;
                font-weight: bold;
                &:hover{
                    background: $preto;
                    cursor: pointer;
                }
            }
            .cor{
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-left: 5px;
                border: 1px solid $cinza_medio;
            }
            .orcamento{
                padding: 5px 10px;
                background-color: $laranja;
                border-radius: 15px;
                margin-bottom: 5px;
                color: $branco;
            }
            .completo{
                padding: 5px 10px;
                background-color: $verde;
                border-radius: 15px;
                color: $branco;
            }
        }
    }

    footer{
        .cadastre-se{
            h4{
                font-size: 15px;
            }
            input{
                width: 69%;
            }
            button{
                padding: 8px 5px;
                width: 28%;
                font-size: 12px;
                vertical-align: top;
            }
        }

        .rodape{
            .logo-rodape{
                flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
                margin-bottom: 15px;
            }
            .links-rodape{
                margin-top: 20px;
                flex: 0 0 100%;
                max-width: 100%;
                .coluna{
                    padding-left: 15px;
                    padding-right: 5px;
                }
                .produtos-rodape{
                    .coluna{
                        float: none;
                        margin-bottom: 10px;
                    }
                }
            }
            .txt{
                margin-top: 20px;
                flex: 0 0 100%;
                max-width: 100%;
            }
            .redes-rodape{
                .zap{
                    text-align: center;
                }
                ul{
                    text-align: center;
                }
            }
            .copy{
                text-align: center;
            }
        }
    }

    .banner-orcamento{
        height: 85px;
        text-align: center;
        h1{
            margin-top: 20px;
            font-size: 40px;
        }
    }
    .carrinho {
        h2{
            font-size: 25px;
        }
        .itens-carrinho{
            .box-pdt{
                margin: 0 10px;
                border-radius: 38px !important;
                h4{
                    font-size: 15px !important;
                }
                .descricao{
                    font-size: 12px;
                    line-height: 15px;
                    ul{
                        padding-left: 17px;
                    }
                }
                .apagar-item-carrinho{
                    position: absolute;
                }
            }
            .opcoes-pdt{
                .escolha-cor{
                    p{
                        font-size: 14px !important;
                        line-height: 15px !important;
                    }
                }
            }
        }
        .botoes-persona label span {
            font-size: 13px;
        }

        .produto-personalizado{
            h3{
                font-size: 19px;
            }
            .escolha-num-cor ul li label {
                width: 140px;
                .box {
                    width: 50px;
                }
            }
        }

        .formulario-carrinho{
            h3{
                font-size: 19px;
            }
            .form-group {
                margin-bottom: 0;
                input{
                    margin-bottom: 7px;
                }
                select{
                    margin-bottom: 7px;
                }
            }
        }

        .formulario-carrinho-ativo {
            height: 713px;
        }

        .vazio{
            text-align: center;
            .btn-carrinho-vazio{
                display: inline-block;
                margin-left: 0;
                margin-top: 30px;
            }
        }
    }
}