.faq-page{
    padding-top: 50px;
    padding-bottom: 50px;
    h1{
        color: $laranja;
        font-size: 60px;
        font-weight: bold;
    }

    #accordionFaqSM{
        margin-top: 50px;
        .btn-link{
            text-transform: uppercase;
            font-weight: bold;
            color: $verde;
            width: 100%;
            text-align: left;
            font-size: 20px;
            cursor: pointer;
        }
    }
}